import React from "react"
import styled from "styled-components"
import { GatsbyImage } from "gatsby-plugin-image"
import { graphql } from "gatsby"
import BlockContent from "@sanity/block-content-to-react"
import Seo from "../components/Seo"
import Layout from "../components/Layout"
import Container from "../components/Container"
import LinkCard from "../components/LinkCard"
import PageHeader from "../components/PageHeader"
import PageContent from "../components/PageContent"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const Links = ({ data }) => {
  const page = data.pageContent
  const bgImage = data.bgImage
  const links = data.links

  return (
    <Layout>
      <Seo title="Links to resources Joss Cannon recommends" />
      <BackgroundImage
        image={bgImage.theImage.asset.gatsbyImageData}
        alt={bgImage.altText}
      />
      <PageHeader />
      <PageContent>
        <Container>
          <ContentContainer>
            <BlockContentContainer>
              <BlockContent blocks={page._rawBody} serializers={serializers} />
            </BlockContentContainer>
          </ContentContainer>
          {links.nodes.map(link => (
            <LinkCard key={link.id} link={link} />
          ))}
        </Container>
      </PageContent>
    </Layout>
  )
}

export default Links

const BackgroundImage = styled(GatsbyImage)`
  position: fixed;
  top: 0;
  left: 0;
  max-height: 60vh;
`

const ContentContainer = styled.div`
  text-align: center;
`

const BlockContentContainer = styled.div`
  margin-bottom: 1.25rem;

  @media screen and (min-width: ${({ theme }) => theme.mobile}) {
    margin-bottom: 2rem;
  }
`

export const query = graphql`
  query LinksPage {
    pageContent: sanityPage(slug: { current: { eq: "links" } }) {
      slug {
        current
      }
      title
      _rawBody
      altText
    }
    bgImage: sanitySingleImage(Id: { eq: "treasure-boxes" }) {
      theImage {
        asset {
          gatsbyImageData
        }
      }
      altText
    }
    links: allSanityLink {
      nodes {
        _rawDescription
        title
        href
        id
      }
    }
  }
`
