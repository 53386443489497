import * as React from "react"
import styled from "styled-components"
import BlockContent from "@sanity/block-content-to-react"

const serializers = {
  types: {
    code: props => (
      <pre data-language={props.node.language}>
        <code>{props.node.code}</code>
      </pre>
    ),
  },
}

const LinkCard = ({ link }) => {
  return (
    <CardContent>
      <CardText>
        <LinkTitle>{link.title}</LinkTitle>
        <BlockContent blocks={link._rawDescription} serializers={serializers} />
        <a href={link.href} rel="noreferrer" target="_blank">
          <LinkUrl>{link.href}</LinkUrl>
        </a>
      </CardText>
    </CardContent>
  )
}

export default LinkCard

const CardContent = styled.article`
  position: relative;
  width: 100%;
  border-radius: 4px;
  margin-bottom: 50px;
  background-color: ${({ theme }) => theme.bgSecondaryLight};
  box-shadow: 1px 1px 5px rgb(0 0 0 / 0.3);

  @media screen and (min-width: ${({ theme }) => theme.tablet}) {
    transition: all 0.1s ease;
  }
`

const CardText = styled.article`
  position: relative;
  width: 100%;
  color: ${({ theme }) => theme.primaryDark};
  padding: 1.5rem 1rem 1rem 1rem;
`

const LinkTitle = styled.h3``

const LinkUrl = styled.p`
  font-size: 1rem;
`
